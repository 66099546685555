import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Link } from 'components/atoms';

const DescriptionIndustry = ({
  data,
  className,
  tagsData,
  wide,
  small,
  isSpacer,
  servicesHeader,
}) => {
  const { acf, app_work_tag } = data;
  const { description } = acf || {};

  const tags =
    app_work_tag &&
    app_work_tag.map((tagId, id) => {
      const tag = tagsData.find(item => item.node.wordpress_id === tagId);

      const { path, name } = tag.node;

      const isLink = path === null;

      const textOrLink = isLink ? (
        <Text className="body-sm body-sm_bold">{name}</Text>
      ) : (
        <Link
          className={`description-industry__content__link`}
          href={path}
          isRouter={false}
          target="_blank"
        >
          <Text className="body-sm body-sm_bold">{name}</Text>
        </Link>
      );

      return (
        <div
          className="description-industry__content__tags__link-item"
          key={id}
        >
          {textOrLink}
        </div>
      );
    });

  return (
    <Section
      className={cn(
        'description-industry',
        small && 'description-industry--small',
        isSpacer && 'description-industry_spacer',
        [className]
      )}
      id="descriptionIndustry"
    >
      <Container className="description-industry__content-container">
        <Row className="description-industry__content">
          <Column
            lg={wide ? '7' : small ? '8' : '6'}
            className={cn(
              'description-industry__content-description',
              !small && 'offset-lg-2'
            )}
          >
            <div
              className={cn('description-industry__content-description__text')}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Column>
          {tags !== null && (
            <Column
              lg="3"
              className="offset-lg-1 description-industry__content__tags"
            >
              <div className="description-industry__content__tags__header">
                <Text className="body-sm">{servicesHeader}</Text>
              </div>
              {tags}
            </Column>
          )}
        </Row>
      </Container>
    </Section>
  );
};

DescriptionIndustry.defaultProps = {
  className: '',
  wide: false,
  servicesHeader: 'Our Services',
};

DescriptionIndustry.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  wide: PropTypes.bool,
  servicesHeader: PropTypes.string,
};

export default DescriptionIndustry;
